<template>
  <div>
    <HeroInner descrition="Dashboard Campanhas" title :ShowLogo="true" />
    <div>
      <!-- <div class="container w-full" v-if="!loadedFirstTime">
        <h1>Carregando...</h1>
      </div>-->
      <!-- v-if="loadedFirstTime" -->
      <v-container grid-list-md fluid style="max-width:1440px">
        <!-- SEM TAREFAS -->
        <!-- <v-layout bg-white mt-5 column br-4 elevation-base>
					<div class="my-donations-header column display-flex fill-height">
						<div>
							<Icon name="fal fa-tasks" size="30" class="mr-3" colorFont="#C3C6D5" />
							<h2>Minhas Tarefas</h2>
						</div>
						<div>
							<InputSearch type="text" placeholder="Buscar..." />
						</div>
					</div>
					<div class="fill-height justify-center align-center display-flex m-height-600">
						<div class="box-done-task">
							<img src="../../../assets/images/EmptyTasks@3x.png" alt />
							<H2>Parabéns, você completou todas as tarefas.</H2>
							<p>
								Uma dica, vá para a página de
								<a href="#">Visão Geral</a> e acompanhe, em
								<br />tempo real, como estão indo suas campanhas.
							</p>
							<a
								href="#"
								class="btn-primary v-btn--largee ma-0 v-btn--large v-btn--round white--text v-btn"
							>Visão Geral</a>
						</div>
					</div>
        </v-layout>-->

        <!-- empty state -->
        <v-flex shrink>
          <v-btn
            flat
            large
            type="button"
            color="white"
            class="btn-blue ma-0 mt-1 px-4 ml-3"
            v-on="on"
            @click="goToVolunteeringDashboard()"
            v-if="institutionOffers.length"
          >
            <Icon name="fal fa-clipboard-list" size="16" class="mr-2" colorFont="#fff" />Dashboard Voluntariado
          </v-btn>
        </v-flex>
        <v-layout pa-4 ma-4 column br-4 v-if="!hasDashboardPermissions">
          <div class="fill-height p-relative justify-center align-center display-flex m-height-600">
            <div class="info-menu">
              Acesse aqui o menu da sua empresa.
              <img
                class="ml-3"
                src="../../../assets/images/seta.svg"
                alt
              />
            </div>
            <div class="box-done-task">
              <div class="il il-BusinessDataMetrics"></div>
              <H2>
                Estamos criando uma tela incrível
                <br />para você acompanhar suas estatísticas.
              </H2>
            </div>
          </div>
        </v-layout>

        <!--################# PAGINA EM BRANCO ###########################-->
        <!-- <v-layout
          pa-4
          ma-4
          column
          br-4
          v-if="dashboardData.campaignsQty == 0 && dashboardData.projectsQty == 0"
        >
          <div class="fill-height p-relative justify-center align-center display-flex m-height-600">
            <div class="info-menu">
              Acesse aqui o menu da sua empresa.
              <img
                class="ml-3"
                src="../../../assets/images/seta.svg"
                alt
              />
            </div>
            <div class="box-done-task">
              <div class="il il-NothingFoundHere"></div>
              <h2
                class="mt-0"
              >Você não tem campanhas ativas, ou suas campanhas ainda não retornaram dados.</h2>
              <p>Uma dica: vá para sua lista de campanhas, crie novas campanhas ou publique as que já tenha criado.</p>
              <v-btn class="v-btn--large round btn-primary text-white mt-3">Campanhas</v-btn>
            </div>
          </div>
        </v-layout>-->
        <!--################# / PAGINA EM BRANCO ###########################-->

        <!-- DASH CAMPANHAS -->
        <!-- dashboardData.campaignsQty != 0 || dashboardData.projectsQty != 0 &&  -->
        <template v-if="hasDashboardPermissions">
          <FilterOverview
            ref="filterOverview"
            :projects="filters.projects"
            :campaigns="filters.campaigns"
            :projectTypes="[{id:1, name:'Projeto incentivado'}, {id:2, name:'Projeto não incentivado'}, {id:3, name:'Ticket'}]"
            :causes="filters.causes"
            :captureStatus="[{id:true, name:'Aberto para doação'}, {id:false, name:'Fechado para doação'}]"
            :employeeStatusList="[{id:true, name:'Sim'}, {id:false, name:'Não'}]"
            :projectPhases="allProjectPhases"
            :recurrences="filters.recurrences"
            :paymentMethods="filters.paymentMethods"
            :states="filters.states"
            :genders="filters.genders"
            :loading="loading"
            @applyFilters="loadDashboad($event)"
          />
          <!-- NUMBERS -->
          <v-layout row wrap justify-space-between text-xs-center>
            <v-flex xs12 sm6 lg3>
              <!-- item card-->
              <v-flex card column>
                <v-flex grow d-flex align-center>
                  <h2>
                    {{formatterHelper.formatShortNumber(dashboardData.donatedTotalValue, 1)}}
                    <span>R$ {{formatterHelper.formatMoney(dashboardData.uniquelyDonatedTotalValue)}} U / R$ {{formatterHelper.formatMoney(dashboardData.recurrentlyDonatedTotalValue)}} R</span>
                    <span>R$ {{formatterHelper.formatMoney(dashboardData.capturedTotalDonationValue)}} C </span>
                  </h2>
                </v-flex>
                <v-flex card-label shrink>Total Doado (R$)</v-flex>
                <div class="loader-circle br-8" v-show="loading">
                  <v-progress-circular :value="20" indeterminate color="teal"></v-progress-circular>
                </div>
              </v-flex>
            </v-flex>

            <v-flex xs12 sm6 lg3>
              <!-- item card-->
              <v-flex card column>
                <v-flex grow d-flex align-center>
                  <h2>
                    {{formatterHelper.formatShortNumber(dashboardData.totalDonors, 1)}}
                    <span>{{dashboardData.uniquelyDonors}} U / {{dashboardData.recurrentlyDonors}} R</span>
                  </h2>
                </v-flex>

                <v-flex card-label shrink>Doações</v-flex>
                <div class="loader-circle br-8" v-show="loading">
                  <v-progress-circular :value="20" indeterminate color="teal"></v-progress-circular>
                </div>
              </v-flex>
            </v-flex>

            <v-flex xs12 sm6 lg3>
              <!-- item card-->
              <v-flex card column>
                <v-flex grow d-flex align-center>
                  <h2>{{formatterHelper.formatShortNumber(dashboardData.campaignsQty, 1)}}</h2>
                </v-flex>
                <v-flex card-label shrink>Campanhas</v-flex>
                <div class="loader-circle br-8" v-show="loading">
                  <v-progress-circular :value="20" indeterminate color="teal"></v-progress-circular>
                </div>
              </v-flex>
            </v-flex>

            <v-flex xs12 sm6 lg3>
              <!-- item card-->
              <v-flex card column>
                <v-flex grow d-flex align-center>
                  <h2>{{formatterHelper.formatShortNumber(dashboardData.projectsQty, 1)}}</h2>
                </v-flex>
                <v-flex card-label shrink>Projetos</v-flex>
                <div class="loader-circle br-8" v-show="loading">
                  <v-progress-circular :value="20" indeterminate color="teal"></v-progress-circular>
                </div>
              </v-flex>
            </v-flex>
          </v-layout>

          <!-- resumo campanhas ##################### -->
          <div class="display-flex justify-space-between">
            <h2 class="title-section-alone mb-3 mt-5">Resumo das Campanhas</h2>
            <div class="display-flex align-center">
              <InputSelect
                id="sortBy"
                class="w-initial"
                :items="groupByOptions"
                textAttribute="name"
                valueAttribute="id"
                v-model="groupBySelectedOption"
                :hasUnselectedOption="false"
              />
            </div>
          </div>
          <v-layout
            bg-white
            pa-4
            mb-3
            column
            br-4
            elevation-base
            p-relative
            v-for="campaign in dashboardData.campaigns"
            :key="campaign.id"
          >
            <v-layout class="title-section-box" row wrap>
              <v-flex xs12 sm6>
                <h5>{{campaign.companyName}}</h5>
                <h3>{{campaign.name}}</h3>
              </v-flex>
              <v-flex xs12 sm6 text-xs-right>
                <v-btn
                  flat
                  class="btn-ghost-gray fw-600"
                  v-show="showDetails[campaign.id]"
                  @click="$set(showDetails, campaign.id, false)"
                >
                  <Icon name="fal fa-minus-circle" size="16" class="mr-3" colorFont="#C3C6D5" />Resumo
                </v-btn>
                <v-btn
                  flat
                  class="btn-ghost-gray fw-600"
                  v-show="!showDetails[campaign.id]"
                  @click="$set(showDetails, campaign.id, true)"
                >
                  <Icon name="fal fa-plus-circle" size="16" class="mr-3" colorFont="#C3C6D5" />Detalhes
                </v-btn>
              </v-flex>
            </v-layout>

            <v-layout values-boxes mt-4 wrap>
              <v-flex xs12 sm6 md3>
                <div class="item">
                  <span>Valor captado</span>
                  <div>R${{formatterHelper.formatShortNumber(campaign.donatedTotalValue,2)}}</div>
                </div>
              </v-flex>
              <v-flex xs12 sm6 md3>
                <div class="item">
                  <span>Doações</span>
                  <div>
                    {{campaign.uniquelyDonors}}
                    <span title="Única">U</span>
                    / {{campaign.recurrentlyDonors}}
                    <span title="Recorrente">R</span>
                  </div>
                </div>
              </v-flex>
              <v-flex xs12 sm6 md2>
                <div class="item">
                  <span>Média de doação</span>
                  <div>R${{formatterHelper.formatShortNumber(campaign.donationAverage, 1)}}</div>
                </div>
              </v-flex>
              <v-flex xs12 sm6 md2>
                <div class="item">
                  <span>Projetos</span>
                  <div>{{campaign.projectsQty}}</div>
                </div>
              </v-flex>
              <v-flex xs12 sm6 md2>
                <div class="item">
                  <span>Empresas do Grupo</span>
                  <div>{{campaign.companiesQty}}</div>
                </div>
              </v-flex>
            </v-layout>

            <div class="mt-4" v-show="showDetails[campaign.id]">
              <v-data-table
                class="grid-data"
                :headers="projectHeaders"
                :items="campaign.projects"
                item-key="projectName"
                v-show="groupBySelectedOption == 2"
                :rows-per-page-items="[-1]"
              >
                <template v-slot:items="props">
                  <tr @click="props.expanded = !props.expanded">
                    <td class="pl-1">
                      <v-btn small flat icon>
                        <Icon name="fal fa-chevron-right" size="16" v-if="props.expanded" />
                        <Icon name="fal fa-chevron-down" size="16" v-else />
                      </v-btn>
                      {{props.item.projectName}}
                    </td>
                    <td
                      class="text-xs-left"
                    >R$ {{formatterHelper.formatMoney(props.item.totalDonation)}}</td>
                    <td
                      class="text-xs-left"
                    >R$ {{formatterHelper.formatMoney(props.item.averageDonation)}}</td>
                    <td class="text-xs-left">{{props.item.donorsQty}}</td>
                  </tr>
                </template>
                <template v-slot:expand="props">
                  <v-card
                    flat
                    class="display-flex"
                    v-for="company in props.item.companies"
                    :key="company.id"
                  >
                    <v-card-text class="justify-start">{{company.companyName}}</v-card-text>
                    <v-card-text
                      class="justify-start"
                    >R$ {{formatterHelper.formatMoney(company.totalDonation)}}</v-card-text>
                    <v-card-text
                      class="justify-start"
                    >R$ {{formatterHelper.formatMoney(company.averageDonation)}}</v-card-text>
                    <v-card-text class="justify-start">{{company.donorsQty}}</v-card-text>
                  </v-card>
                </template>
              </v-data-table>
              <v-data-table
                class="grid-data"
                :headers="companyHeaders"
                :items="campaign.companies"
                item-key="companyName"
                v-show="groupBySelectedOption == 1"
                :rows-per-page-items="[-1]"
              >
                <template v-slot:items="props">
                  <tr @click="props.expanded = !props.expanded">
                    <td class="pl-1">
                      <v-btn small flat icon>
                        <Icon name="fal fa-chevron-down" size="16" v-if="props.expanded" />
                        <Icon name="fal fa-chevron-right" size="16" v-else />
                      </v-btn>
                      {{props.item.companyName}}
                    </td>
                    <td
                      class="text-xs-left"
                    >R$ {{formatterHelper.formatMoney(props.item.totalDonation)}}</td>
                    <td
                      class="text-xs-left"
                    >R$ {{formatterHelper.formatMoney(props.item.averageDonation)}}</td>
                    <td class="text-xs-left">{{props.item.donorsQty}}</td>
                  </tr>
                </template>
                <template v-slot:expand="props">
                  <v-card
                    flat
                    class="display-flex"
                    v-for="project in props.item.projects"
                    :key="project.id"
                  >
                    <v-card-text class="justify-start">{{project.projectName}}</v-card-text>
                    <v-card-text
                      class="justify-start"
                    >R$ {{formatterHelper.formatMoney(project.totalDonation)}}</v-card-text>
                    <v-card-text
                      class="justify-start"
                    >R$ {{formatterHelper.formatMoney(project.averageDonation)}}</v-card-text>
                    <v-card-text class="justify-start">{{project.donorsQty}}</v-card-text>
                  </v-card>
                </template>
              </v-data-table>
            </div>
            <div class="loader-circle br-8" v-show="loading">
              <v-progress-circular :value="20" indeterminate color="teal"></v-progress-circular>
            </div>
          </v-layout>

          <v-layout justify-space-between row wrap align-center>
            <h2 class="title-section-alone my-4">Visão Geral</h2>
            <div class="toggle-btn-custom">
              <input id="sid" type="checkbox" v-model="showAnalyticalData" />
              <label for="sid">
                <Icon name="fas fa-chart-pie" size="14" colorFont="#fff" />
                <Icon name="fas fa-table" size="14" colorFont="#fff" />
              </label>
            </div>
          </v-layout>
          <DashboardAnalyticalData
            v-show="showAnalyticalData"
            :data="dashboardData.donationList"
            :loading="loading"
          />
          <Charts v-show="!showAnalyticalData" :chartsData="chartsData" :loading="loading" />
        </template>
      </v-container>
    </div>
    <!-- <DialogError></DialogError>
		<DialogInvite></DialogInvite>
    <EmployeeBase></EmployeeBase>-->
  </div>
</template>
<script type="plain/text">
// AIzaSyDn53tMbvEe-c5EexIOneIZsYSFMd0U7Ls
import Modal from "@/components/Modal.vue";
import FilterOverview from "./modal/FilterOverview.vue";
import HeroInner from "@/components/HeroInner.vue";
import DialogError from "@/components/DialogError.vue";
import DialogInvite from "@/components/DialogInvite.vue";
import EmployeeBase from "@/components/EmployeeBase.vue";
import { Chart } from "highcharts-vue";
import TokenService from "@/scripts/services/token.service";
import Charts from "@/views/app/dashboard/component/Charts.vue";
import DashboardAnalyticalData from "@/views/app/dashboard/component/DashboardAnalyticalData.vue";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import CompanyService from "@/scripts/services/company.service";
import { AllProjectPhases } from "@/scripts/models/enums/projectPhases.enum";
import ChartsData from "@/scripts/models/chartsData.model";
import DashboardData from "@/scripts/models/dashboardData.model";
import DonationRecurrencePeriod from "@/scripts/models/enums/donationRecurrencePeriod.enum";
import { Routes } from "@/scripts/models/enums/routes.enum";
import { mapGetters, mapActions, mapState } from "vuex";
import Permissions from "@/scripts/models/enums/permissions.enum";
import ReportService from "@/scripts/services/report.service";

export default {
  components: {
    HeroInner,
    FilterOverview,
    Modal,
    Charts,
    DashboardAnalyticalData
  },
  data: () => ({
    hasDashboardPermissions: false,
    loadedFirstTime: false,
    loading: false,
    routes: Routes,
    allProjectPhases: AllProjectPhases,
    companyService: new CompanyService(),
    reportService: new ReportService(),
    groupByOptions: [{ id: 1, name: "Empresa" }, { id: 2, name: "Projeto" }],
    groupBySelectedOption: 1,
    dashboardData: new DashboardData(),
    chartsData: new ChartsData(),
    formatterHelper: new FormatterHelper(),
    expand: false,
    showAnalyticalData: false,
    projectHeaders: [
      {
        text: "Projetos",
        align: "left",
        sortable: false,
        value: "projectName"
      },
      { text: "Captado", value: "totalDonation" },
      { text: "Doação Média", value: "averageDonation" },
      { text: "Doações", value: "donorsQty" }
    ],
    companyHeaders: [
      {
        text: "Empresas",
        align: "left",
        sortable: false,
        value: "companyName"
      },
      { text: "Captado", value: "totalDonation" },
      { text: "Doação Média", value: "averageDonation" },
      { text: "Doações", value: "donorsQty" }
    ],
    filters: Object,
    showDetails: []
  }),
  computed: {
    ...mapGetters(["hasPermission", "institutionOffers"])
  },
  created() {
    if (!this.$route.query.entityId)
      this.$router.push({
        name: Routes.app.DashboardPF
      });
    this.$store.watch(
      state => state.permissionsLoaded,
      () => {
        this.updateHasDashboardPermissions();
      }
    );
    this.updateHasDashboardPermissions();
    /*this.companyService
      .getDashboardFilters(this.$route.query.entityId)
      .then(this.companyService_getDashboardFiltersCallback);*/

    this.setInstitutionOffers(this.$route.query.entityId, this.$route.query.institutionId);
  },
  mounted() {
    if (this.$refs.filterOverview)
      this.loadDashboad(this.$refs.filterOverview.getFilters());
  },
  methods: {
    ...mapActions(["setInstitutionOffers"]),
    goToVolunteeringDashboard(){
      this.$router.push({ name: this.routes.app.DashboardVolunteering, query: { entityId: this.$route.query.entityId } })
    },
    updateHasDashboardPermissions() {
      this.hasDashboardPermissions = this.hasPermission(
        [Permissions.Dashboard],
        this.$route.query.entityId
      );
      if (this.$refs.filterOverview)
        this.loadDashboad(this.$refs.filterOverview.getFilters());
    },
    loadDashboad(selectedFilters = null) {
      if (!this.hasDashboardPermissions) {
        return;
      }
      this.loading = true;
      if (!selectedFilters) {
        selectedFilters = {};
        selectedFilters.entityId = this.$route.query.entityId;
        let firstDate = new Date();
        firstDate.setDate(firstDate.getDate() - 30);
        selectedFilters.initialDate = firstDate;
        selectedFilters.finalDate = new Date();
        selectedFilters.dashboardFiltersLoaded = false;
        selectedFilters.projectPhases = [1, 2];
        selectedFilters.projectTypes = [1, 2];
        selectedFilters.captureStatus = [true, false];
        selectedFilters.employeeStatus = [true, false];
      }
      this.companyService
        .loadDashboard(selectedFilters)
        .then(this.companyService_loadDashboardCallback)
        .catch(this.companyService_loadDashboardCallbackCatch);
    },
    companyService_loadDashboardCallback(data) {
      if (data) {
        this.showDetails = [];
        for (let i = 0; i < data.campaigns.length; i++) {
          this.showDetails[data.campaigns[i].id] = false;
        }
        this.dashboardData = data;
        this.filters = data.dashboardFiltersViewModel;
        // if (this.chartsData == null) this.chartsData = {};
        this.chartsData.pieChartByDonationRecurrence =
          data.totalDonationByRecurrence;
        this.chartsData.pieChartByPaymentMethod =
          data.totalDonationByPaymentMethod;
        this.chartsData.pieChartByGender = data.totalDonationByGender;
        this.chartsData.pieChartByAgeRange = data.totalDonationByAgeRange;
        this.chartsData.pieChartByProject = data.totalDonationByProject;
        this.chartsData.pieChartByProjectCause =
          data.totalDonationByProjectCause;
        this.chartsData.pieChartByProjectCaptureStatus =
          data.totalDonationByProjectCaptureStatus;
        this.chartsData.pieChartByProjectPhase =
          data.totalDonationByProjectPhase;
        this.chartsData.pieChartByProjectType = data.totalDonationByProjectType;
        this.chartsData.stateData = data.totalDonationByState;
        this.chartsData.donationHistory = data.donationHistory;
      }
      this.loadedFirstTime = true;
      this.loading = false;
    },
    companyService_loadDashboardCallbackCatch() {
      this.loadedFirstTime = true;
      this.loading = false;
    },
    companyService_getDashboardFiltersCallback(data) {
      this.filters = data;
    },
  }
};
</script>